import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql, StaticQuery } from 'gatsby'
import PreviewCompatibleImage from './PreviewCompatibleImage'
import { HTMLContent } from '../components/Content'

class BlogRoll extends React.Component {
    render() {
        const { data } = this.props
        const { edges: posts } = data.allMarkdownRemark

        return (
            <div className="columns is-multiline">
                <div className="column is-full">
                    {posts &&
                        posts.map(({ node: post }) => (
                            <article key={post.frontmatter.title} className={`blog-list-item is-child box`}>
                                <header>
                                    {post.frontmatter.featuredimage ? (
                                        <div className="featured-thumbnail">
                                            <Link to={post.fields.slug} title={post.frontmatter.title}>
                                                <PreviewCompatibleImage
                                                    imageInfo={{
                                                        image: post.frontmatter.featuredimage,
                                                        alt: `featured image thumbnail for post ${post.title}`
                                                    }}
                                                />
                                            </Link>
                                        </div>
                                    ) : null}
                                    <div className="post-meta">
                                        <div className="is-size-5 is-block">{post.frontmatter.date}</div>
                                        <Link className="title has-text-primary is-size-4" to={post.fields.slug}>
                                            {post.frontmatter.title}
                                        </Link>
                                        <p />
                                        <HTMLContent content={post.excerpt} />
                                    </div>
                                </header>
                                <div style={{ textAlign: 'right' }}>
                                    <br />
                                    <Link className="button" to={post.fields.slug}>
                                        Keep Reading →
                                    </Link>
                                </div>
                            </article>
                        ))}
                </div>
            </div>
        )
    }
}

BlogRoll.propTypes = {
    data: PropTypes.shape({
        allMarkdownRemark: PropTypes.shape({
            edges: PropTypes.array
        })
    })
}

const newLocal = graphql`
    query BlogRollQuery {
        allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 600, format: HTML)
                    id
                    fields {
                        slug
                    }
                    frontmatter {
                        title
                        templateKey
                        date(formatString: "MMMM DD, YYYY")
                        featuredimage {
                            childImageSharp {
                                fluid(maxWidth: 120, quality: 100) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`

export default () => <StaticQuery query={newLocal} render={(data, count) => <BlogRoll data={data} count={count} />} />
